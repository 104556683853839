<template>
  <form @submit.prevent="savePeople" class="-my-3">
    <div v-if="mode == 'create'" class="my-3">
      <label class="uppercase font-semibold text-sm">Role</label>
      <div class="relative w-full">
        <select
          v-model="people.role"
          required
          class="bge-input bge-select rounded"
        >
          <template v-for="option in roles">
            <option :key="option.name" :value="option.name">
              {{ option.name }}
            </option>
          </template>
        </select>
        <div
          class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
        >
          <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
            <path
              d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="flex flex-col w-full my-3">
      <label class="uppercase font-semibold text-sm">
        First Name
        <span class="text-red-400">*</span>
      </label>
      <div
        class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
      >
        <input
          v-model="people.first_name"
          required
          class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
        />
      </div>
    </div>
    <div class="flex flex-col w-full my-3">
      <label class="uppercase font-semibold text-sm">
        Last Name
        <span class="text-red-400">*</span>
      </label>
      <div
        class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
      >
        <input
          v-model="people.last_name"
          required
          class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
        />
      </div>
    </div>
    <div class="flex flex-col w-full my-3">
      <label class="uppercase font-semibold text-sm">
        Email
        <span class="text-red-400">*</span>
      </label>
      <div
        class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
      >
        <input
          v-model="people.email"
          required
          class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
        />
      </div>
    </div>
    <div class="flex flex-col w-full my-3">
      <label class="uppercase font-semibold text-sm">
        Mobile
        <span class="text-red-400">*</span>
      </label>
      <div
        class="flex border rounded border-gray-400 bg-gray-100 hover:border-primary focus-within:border-primary focus:shadow"
      >
        <input
          v-model="people.contact_no"
          required
          class="w-full bg-gray-100 rounded-r py-2 pl-1 pr-4"
        />
      </div>
    </div>
    <div class="flex flex-col w-full my-3">
      <label class="uppercase font-semibold text-sm">Comment</label>
      <textarea
        v-model="people.comment"
        class="text-secondary text-lg leading-snug w-full focus:outline-none bge-input bge-input-spacing rounded rounded mb-3"
        placeholder="Description"
      ></textarea>
    </div>
    <button
      type="submit"
      class="float-right mt-5 flex items-center bg-green-200 hover:bg-green-300 text-secondary py-1 px-3 rounded border border-green-400 hover:border-green-500 text-secondary hover:shadow active:shadow-inner transition duration-100"
    >
      <svg
        class="stroke-current h-5 w-5 mr-2"
        fill="none"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        viewBox="0 0 24 24"
      >
        <path d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z" />
        <path d="M17 21v-8H7v8M7 3v5h8" />
      </svg>
      <span v-if="mode == 'create'">Add People</span>
      <span v-if="mode == 'edit'">Update People</span>
    </button>
  </form>
</template>

<script>
export default {
  name: "AddEditPeople",
  props: {
    people: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      mode: "",
      roles: [
        { name: "Witness", id: "1" },
        { name: "First Aider", id: "2" },
      ],
    };
  },
  mounted() {
    if (this.people.staff_id) {
      this.mode = "edit";
    } else {
      this.mode = "create";
    }
  },

  methods: {
    savePeople: function () {
      if (this.mode == "create") {
        this.people.staff_id = this.guidGenerator();
      }
      this.$emit("complete", this.people);
    },
    //TODO Repalce with actual staff
    guidGenerator: function () {
      var S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      };
      return (
        S4() +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        "-" +
        S4() +
        S4() +
        S4()
      );
    },
  },
};
</script>
