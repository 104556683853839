<template>
  <form
    @submit.prevent="submitIncident"
    autocomplete="off"
    class="w-full max-h-screen bg-background flex flex-col relative"
    :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
      v-show="isBusy"
      class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10" />
    </div>
    <page-header :title="pageTitle" back-to="incident">
      <button
        type="submit"
        class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
          class="stroke-current h-5 w-5 mr-2"
          fill="none"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          viewBox="0 0 24 24"
        >
          <path
            d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8" />
        </svg>
        <span>Save</span>
      </button>
    </page-header>

    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <!-- Incident Details -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
              class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Incident Details
            </h3>
            <hr class="flex-1 border-primary ml-4" />
          </div>

          <div class="flex w-full">
            <div class="w-2/3 flex flex-col pr-3">
              <div class="flex flex-wrap -mx-5">
                <!-- Type -->
                <div class="flex flex-col w-full lg:w-1/2 px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Type</label>
                  <div class="inline-block relative w-full">
                    <select
                      v-model="newIncident.type"
                      class="bge-input bge-select rounded w-full"
                    >
                      <option>Accident</option>
                      <option>Illness</option>
                      <option>Incident</option>
                      <option>Injury</option>
                      <option>Safety Violation</option>
                      <option>Unsafe Practice</option>
                      <option>Vehicle/Equipment Accident</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <!-- Person Involved -->
                <div class="w-full lg:w-1/2 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Reported By</label
                  >
                  <input
                    v-model="newIncident.reported_by"
                    class="bge-input bge-input-spacing rounded w-full"
                    type="text"
                  />
                </div>

                <!-- Date -->
                <div class="flex flex-col w-full lg:w-1/2 px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">Date</label>
                  <Calendar
                    v-model="newIncident.incident_date"
                    :showTime="true"
                    hourFormat="12"
                  />
                </div>

                <!-- Time -->
                <!--                <div class="flex flex-col w-full lg:w-1/2 px-5">-->
                <!--                  <label class="uppercase font-semibold text-sm">Time</label>-->
                <!--                  <Calendar-->
                <!--                    v-model="newIncident.date"-->
                <!--                    :showTime="true"-->
                <!--                    hourFormat="12"-->
                <!--                    :timeOnly="true"-->
                <!--                  />-->
                <!--                </div>-->
              </div>
            </div>

            <!-- Description -->

            <div class="flex flex-col w-full lg:w-1/3 px-5">
              <label class="uppercase font-semibold text-sm">Description</label>
              <textarea
                v-model="newIncident.description"
                class="text-secondary text-lg leading-snug w-full focus:outline-none bge-input bge-input-spacing rounded h-32"
                placeholder="Incident description"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- Incident Location -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
              class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Incident Location
            </h3>
            <hr class="flex-1 border-primary ml-4" />
          </div>

          <div class="flex w-full">
            <!-- Location Type and Location Name -->
            <div class="w-2/3 flex flex-col pr-3">
              <div class="flex flex-wrap -mx-5">
                <!-- Location Type -->
                <div class="flex flex-col w-full lg:w-1/2 px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Location Type</label
                  >
                  <div class="inline-block relative w-full">
                    <select
                      v-model="newIncident.location_type"
                      class="bge-input bge-select rounded w-full"
                    >
                      <option>Workplace</option>
                      <option>Customer Site</option>
                      <option>Vehicle</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>

                <!-- Location Name -->
                <div class="flex flex-col w-full lg:w-1/2 px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Location</label
                  >
                  <input
                    v-model="newIncident.location_name"
                    class="bge-input bge-input-spacing rounded w-full"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <!-- Address -->
            <div class="flex flex-col w-full lg:w-1/3 px-5">
              <label class="uppercase font-semibold text-sm">Address</label>
              <textarea
                v-model="newIncident.location_address"
                class="text-secondary text-lg leading-snug w-full focus:outline-none bge-input bge-input-spacing rounded h-32"
                placeholder="Incident address"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- Additional Details -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
              class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Additional Details
            </h3>
            <hr class="flex-1 border-primary ml-4" />
          </div>

          <div class="flex w-full">
            <!-- Inputs Section -->
            <div class="w-2/3 flex flex-col pr-3">
              <div class="flex flex-wrap -mx-5">
                <!-- Result of the incident -->
                <div class="flex flex-col w-full lg:w-1/2 px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Result of the incident</label
                  >
                  <div class="inline-block relative w-full">
                    <select
                      v-model="newIncident.result_of_incident"
                      class="bge-input bge-select rounded"
                    >
                      <option>Injury</option>
                      <option>Illness</option>
                      <option>Nothing</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>

                <!-- What action was taken -->
                <div class="w-full lg:w-1/2 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >What action was taken</label
                  >
                  <div class="inline-block relative">
                    <select
                      v-model="newIncident.treatment_received"
                      class="bge-input bge-select rounded"
                    >
                      <option>First Aid</option>
                      <option>Ambulance called</option>
                      <option>Hospital</option>
                      <option>Police</option>
                      <option>Other</option>
                    </select>
                    <div
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                        <path
                          d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>

                <!-- Source of injury/illness -->
                <div class="flex flex-col w-full lg:w-1/2 px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Source of injury/illness</label
                  >
                  <input
                    v-model="newIncident.source_of_illness"
                    class="bge-input bge-input-spacing rounded"
                    type="text"
                  />
                </div>

                <!-- Body Part Affected -->
                <div class="flex flex-col w-full lg:w-1/2 px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Body Part Affected</label
                  >
                  <input
                    v-model="newIncident.affected_body_part"
                    class="bge-input bge-input-spacing rounded"
                    type="text"
                  />
                </div>

                <!-- Nature of injury/illness -->
                <div class="flex flex-col w-full lg:w-1/2 px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Nature of injury/illness</label
                  >
                  <input
                    v-model="newIncident.nature_of_illness"
                    class="bge-input bge-input-spacing rounded w-full"
                    type="text"
                  />
                </div>

                <!-- Event or Exposure -->
                <div class="flex flex-col w-full lg:w-1/2 px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                    >Event or Exposure</label
                  >
                  <input
                    v-model="newIncident.event"
                    class="bge-input bge-input-spacing rounded w-full"
                    type="text"
                  />
                </div>
              </div>
            </div>

            <!-- Description -->
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 px-5">
              <label class="uppercase font-semibold text-sm">Description</label>
              <textarea
                v-model="newIncident.additional_description"
                class="text-secondary text-lg leading-snug w-full focus:outline-none bge-input bge-input-spacing rounded h-32"
                placeholder="Incident description"
              ></textarea>
            </div>
          </div>
        </div>
      </div>

      <!-- People Connected -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
              class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              People Connected
            </h3>
            <hr class="flex-1 border-primary ml-4" />
          </div>
          <div class="flex flex-wrap -my-3 -mx-5">
            <div class="flex flex-col w-full my-3 px-5">
              <div class="w-full">
                <table class="table-auto w-full">
                  <thead>
                    <tr class="text-left border-b border-primary">
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">
                        Name
                      </th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">
                        Role
                      </th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">
                        Email
                      </th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">
                        Contact No.
                      </th>
                      <th class="px-4 pb-1 uppercase font-semibold text-sm">
                        Comment
                      </th>
                      <th
                        class="px-4 pb-1 uppercase font-semibold text-sm"
                      ></th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, i) in newIncident.connected_people">
                      <tr
                        :key="i"
                        class="hover:bg-orange-200 even:bg-gray-100 group"
                      >
                        <td class="px-4 py-2">
                          {{ `${item.last_name} ${item.first_name}` }}
                        </td>
                        <td class="px-4 py-2">{{ item.role }}</td>
                        <td class="px-4 py-2">{{ item.email }}</td>
                        <td class="px-4 py-2">{{ item.contact_no }}</td>
                        <td class="px-4 py-2">{{ item.comment }}</td>
                        <td class="px-4 py-2">
                          <button
                            type="button"
                            @click="removePeople(item)"
                            class="flex items-center ml-2 float-right bg-red-200 border hover:bg-red-300 border-red-400 hover:border-red-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                          >
                            <svg
                              class="stroke-current h-5 w-5 mr-2"
                              fill="none"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2M10 11v6M14 11v6"
                              />
                            </svg>
                            <span>Delete</span>
                          </button>
                          <button
                            type="button"
                            @click="editPeople(item)"
                            class="float-right flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
                          >
                            <svg
                              class="stroke-current h-5 w-5 mr-2"
                              fill="none"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              viewBox="0 0 24 24"
                            >
                              <path
                                d="M17 3a2.828 2.828 0 114 4L7.5 20.5 2 22l1.5-5.5L17 3z"
                              />
                            </svg>
                            <span>Edit</span>
                          </button>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
                <div
                  v-show="newIncident.connected_people.length == 0"
                  class="w-full text-center py-4"
                >
                  No people Added
                </div>
              </div>
              <div class="flex justify-end mb-5 mt-8">
                <button
                  @click="addNewPeople"
                  type="button"
                  class="flex items-center bg-white text-secondary py-1 px-2 font-semibold rounded border border-secondary hover:bg-gray-200 hover:shadow active:shadow-inner transition duration-100"
                >
                  <svg
                    class="stroke-current h-5 w-5 mr-2"
                    fill="none"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    viewBox="0 0 24 24"
                  >
                    <path d="M12 5v14M5 12h14" />
                  </svg>
                  <span>Add people</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <panel
      :showing="showingAddPeoplePanel"
      @close="showingAddPeoplePanel = false"
      :title="incidentPeopleTitle"
    >
      <add-edit-people :people="editingPeople" @complete="savePeople" />
    </panel>
  </form>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Notification from "@/components/Notification.vue";
import Spinner from "@/components/Spinner.vue";
import AddEditPeople from "@/components/Internal/HealthAndSafety/Incident/AddEditPeople.vue";

const Datepicker = () => import("vuejs-datepicker");

export default {
  name: "CreateIncident",
  components: {
    PageHeader,
    Panel,
    Spinner,
    AddEditPeople,
    Datepicker,
  },
  props: {
    incident_id: String,
  },
  data() {
    return {
      showingAddPeoplePanel: false,
      isBusy: false,
      incidentPeopleTitle: "",
      editingPeople: {},
      newIncident: {
        connected_people: [],
      },
    };
  },
  async mounted() {
    if (this.incident_id) {
      await this.loadIncidents();
    }
  },
  computed: {
    pageTitle() {
      return this.incident_id ? "Update Incident" : "Create Incident";
    },
  },
  methods: {
    async loadIncidents() {
      let incident = await this.IncidentService.getIncidentById(
        this.incident_id,
      );
      this.newIncident = incident;
    },
    addNewPeople: function () {
      this.incidentPeopleTitle = "Add People";
      this.editingPeople = {};
      this.showingAddPeoplePanel = true;
    },
    savePeople: function (people) {
      this.showingAddPeoplePanel = false;
      let existingPeople = this.$_.find(
        this.newIncident.connected_people,
        (ppl) => ppl.staff_id == people.staff_id,
      );
      if (existingPeople) {
        existingPeople.first_name = people.first_name;
        existingPeople.last_name = people.last_name;
        existingPeople.role = people.role;
        existingPeople.email = people.email;
        existingPeople.contact_no = people.contact_no;
        existingPeople.comment = people.comment;
        existingPeople.staff_id = people.staff_id;
      } else {
        this.newIncident.connected_people.push(people);
      }
    },
    editPeople: function (people) {
      this.incidentPeopleTitle = "Update People";
      this.editingPeople = {
        ...people,
      };
      this.showingAddPeoplePanel = true;
    },
    removePeople: function (people) {
      this.$_.remove(
        this.newIncident.connected_people,
        (ppl) => ppl.staff_id == people.staff_id,
      );
      this.$forceUpdate();
    },

    submitIncident: async function () {
      this.isBusy = true;
      try {
        if (this.incident_id) {
          await this.IncidentService.updateIncident(this.newIncident);
          this.notifyUser("success", "Incident updated successfully");
        } else {
          await this.IncidentService.createIncident(this.newIncident);
          this.notifyUser("success", "Incident created successfully");
        }
        await this.$router.push({ name: "incident" });
      } catch (error) {
        let errorMessage = this.incident_id
          ? "Couldn't update incident"
          : "Couldn't create incident";
        this.notifyUser("danger", errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },

    notifyUser: function (type, message) {
      this.$breadstick.notify(
        ({ h, onClose }) => {
          return h(Notification, {
            props: {
              type: type,
              title: message,
              close: onClose,
            },
          });
        },
        { position: "top-right" },
      );
    },
  },
};
</script>
